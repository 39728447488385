export enum MainMenuEnum {
  NewProject = 'new-project',
  Projects = 'projects',
  DailyTasks = 'daily-tasks',
  Schedule = 'schedule',
  Scheduler = 'scheduler',
  Billing = '/billing/transactions',
  Settings = 'settings',
  Notification = 'notifications',
  NotificationList = '/settings/notification-template/list',
  Clients = 'clients',
  UpdateClients = '/clients/update',
  Employees = 'employees',
  Zones = 'zones',
  Offers = 'offers',
  Coupons = 'coupons',
  FinanceSettings = 'finance-settings',
  ASettings = 'a-settings',
  PendingUsers = 'leads',
  ZonelessHomes = 'zone-less',
  Analytics = 'analytics',
  Trucks = 'trucks',
  Checkout = 'checkout',
  GarbageCompanies = 'garbage-companies',
  DriverAnalytics = 'driver-analytics',
  CustomerPortal = 'customer-portal',
  Devices = '/devices'
}

export enum MainMenuRouteEnum {
  NewProject = '/new-project',
  Projects = '/projects',
  DailyTasks = '/drives',
  Schedule = '/schedule',
  Billing = '/billing/transactions',
  Settings = '/settings',
  Notification = '/notifications',
  NotificationList = '/settings/notification-template/list',
  Zones = '/settings/zones',
  Clients = '/clients',
  UpdateClients = '/clients/update',
  Employees = '/employees',
  Offers = '/settings/offers',
  Scheduler = '/washing-scheduler',
  Coupons = '/settings/coupons',
  FinanceSettings = '/finance-settings/services',
  ASettings = '/a-settings/settings',
  PendingUsers = '/leads',
  ZonelessHomes = '/clients/zone-less',
  Analytics = '/organizer/zones/analytics',
  DriverAnalytics = '/organizer/driver/analytics',
  Trucks = '/trucks',
  Checkout = '/checkout',
  GarbageCompanies = '/garbage-companies',
  CustomerPortal = '/a-settings/customer-portal',
  Devices = '/a-settings/devices'
}
