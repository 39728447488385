<header>
  <app-common-header
    [userProfile]="userProfile"
    [mainMenu]="mainMenu"
    [notifications]="notifications"
    (logoutClicked)="logout()"
    (showSideMenu)="toggleSideMenu()"
  ></app-common-header>
</header>

<main class="main__wrapper" [class.main__wrapper--animate]="showSidebar">
  <section class="main__sidebar" *ngIf="sidebar">
    <!-- <common-sidebar
      [sidebar]="sidebar"
      [showSidebar]="showSidebar"
    ></common-sidebar> -->
  </section>

  <section class="main__content">

    <app-common-right-sidebar
      [mainMenu]="mainMenu"
      [showSideMenu]="showSideMenu"
    ></app-common-right-sidebar>

    <router-outlet></router-outlet>
  </section>
</main>

<app-loader [showAppLoader]="showAppLoader"></app-loader>
